import React from "react";
import { Link } from "gatsby";
import imgA from "../../assets/image/home-1/png/arrow-down-big.png";
import backgroundImage from "../../assets/image/hero-bg.gif";
import security from '../../assets/image/svg/icons/security.svg';
import training from '../../assets/image/svg/icons/training.svg';


const Hero = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className='pt-15 pt-lg-20'>
        <div
          className='bg-img-1 bg-images pt-18 pt-lg-25 pt-xl-33 mx-lg-13 mx-xl-15 bg-gradient-2'
          style={{ backgroundImage: `url(${backgroundImage})` }}>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-7 col-lg-8 col-md-9'>
                <div className='dark-mode-texts'>
                  <h1 className='font-size-12 mb-8'>Ghana’s foremost information technology centre</h1>
                  <p className='font-size-7 mb-0 text-white'>
                     with over 40 years of  <br className='d-none d-md-block' />  combined
                    faculty experience.
                  </p>
                  <Link className='pt-11' to='/#training-methods'>
                    <img src={imgA} alt='' />
                  </Link>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='mt-13 mt-lg-18 mt-xl-23 w-100 shadow-1'>
                  <form action='/' className='search-form-one' data-aos='fade-up' data-aos-duration='800'>
                    <div className='ocorie-gradient filter-search-form-2 bg-white rounded-0 shadow-7 d-lg-flex justify-content-between pl-lg-6'>
                      <div className='filter-inputs d-xs-flex w-100'>
                        <div className='form-group mb-0 focus-reset w-100 w-lg-50 text-bali-gray d-flex align-items-center'>
                          <div className='row'>
                            <div className='col'>
                              <div className='d-flex'>
                                <div className='square-83 d-flex rounded-10 mt-2'>
                                  <img className='hero-icon' src={security} alt='' style={{ width: 50, height: 50 }} />
                                </div>
                                <div className='mt-0 pl-xs-6 d-flex'>
                                  <h3 className='font-size-8 mt-9' style={{ color: 'white' }}>
                                    CYBER SECURITY TRAINING
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='dotted-border form-group border-top-dotted mb-0 w-100 w-lg-50 position-relative pt-5 pb-6 pt-lg-9 pb-lg-10 py-lg-0 d-flex align-items-center'>
                          <div className='row'>
                            <div className='col'>
                              <div className='d-flex'>
                                <div className='square-83 d-flex rounded-10 mt-2'>
                                  <img className='hero-icon' src={training} alt='' style={{ width: 50, height: 50 }} />
                                </div>
                                <div className='mt-0 pl-xs-6 d-flex'>
                                  <h3 className='font-size-8 mt-9' style={{ color: 'white' }}>
                                    IT TRAINING
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
