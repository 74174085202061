import React from 'react';

import imgC from '../../assets/image/home-1/png/ocorie-hm-splash-img-1.png';

const Content = ({ className, ...rest }) => {
  const contentWidgetData = [

    {
      title: 'CompTIA Certifications',
    },
    {
      title: 'VMware Certifications',
    },
    {
      title: 'SANS Certifications',
    },
    {
      title: 'CISCO Certifications',
    },
    {
      title: 'Microsoft Certifications',
    },
    {
      title: 'European Council Certifications',
    },
    {
      title: 'Offensive Security Certified Professional - OSCP',
    },
    {
      title: 'AWS Certifications',
    }
  ];
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            {/* Left */}
            <div
              className="col-xl-6 col-lg-6 col-md-6 col-xs-8 mt-6 mt-lg-7 mt-xl-5 order-2 order-md-1"
              data-aos="fade-right"
              data-aos-delay={100}
              data-aos-once="true"
            >
              <h2 className="font-size-10">Faculty Credentials</h2>
              <p>Our faculty members are certified information security practitioners with decades of real world experience who are adept at mitigating risks in information security issues. Our industry credentials cut across the following certifications from various vendors such as:</p>
              <ul className="list-unstyled mt-9 mt-lg-14">
                {contentWidgetData.map(({ title }, index) => {
                  return (
                    <li className="media align-items-center mb-5" key={index}>
                      <div className="content">
                        <h5 className="text-default-color-2">{title}</h5>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* Left End */}
            {/* Right */}
            <div
              className="col-xl-4 offset-xl-2 col-lg-7 col-md-6 col-xs-8 order-1 order-md-2"
              data-aos="fade-left"
              data-aos-delay={100}
              data-aos-once="true"
            >
              <div className="l2-content-image-group-2 mt-19 mb-10 mb-md-0 rounded-10">
                <div className="img-1 position-relative text-right rounded-10">
                  <img className="w-100 w-lg-auto" src={imgC} alt="" />
                </div>
              </div>
            </div>
            {/* Right */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
