import React, { useRef } from "react";
import Slider from "react-slick";
import TestimonialCard1 from "../../components/TestimonialCard";

const items = [
  {
    content:
      'After taking the CompTIA Security+ it has added great value and substance to my entire life and eventually elevated me to secure a job here at NCA. The high level knowledge, communication skills and outstanding display of professionalism has remained a great opportunity and has generated good outgrowth in my life.',
    userlink: '- Maxwell D. Sakyi',
    position: 'Cyber Security Officer, NCA'
  },
  {
    content:
      'For someone with a background in Purchasing and Supply and with no real in-depth knowledge in IT, I never once thought I could undertake a course in cybersecurity until a friend introduced me to Ocorie. Their fundamental approach to training enabled me to come to terms with and appreciate even the technicalities with ease. I am more aware of my cyberspace and Ocorie has heightened my interest in cybersecurity. I would definitely recommend Ocorie to anyone.',
    userlink: '- Leonard Xvien Agyei Asante',
    position: 'Waiter/Audio Technician, Marriot Hotel'
  },
  {
    content:
      "I have always wanted to pursue a career in Cybersecurity but didn't know where to start from. I was introduced to Ocorie through a friend and I have not regretted it ever since. I was exposed to a variety of industry examples which made the material easier to understand and apply them in real world scenarios. The training further propelled me to participate in a cyber security audit project and landed me a job with my current employer. Thanks to Ocorie, I have gained a strong foundational knowledge in Security+ to kick-start my career in Cybersecurity.",
    userlink: '- Nana Bonsafo Agyei-Darko',
    position: 'Cyber Security Engineer, Omni Strategies Limited'
  }
];

const Testimonial = ({ className, ...rest }) => {
  const elSlider = useRef();

  const slickSettings = {
    autoplay: false,
    slidesToShow: 2,
    arrows: false,
    infinite: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 468,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="mb-8 mb-lg-5">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-9">
                <div
                  className="mb-8 mb-lg-16 mb-md-0 text-center text-md-left"
                  data-aos="fade-right"
                  data-aos-delay="500"
                >
                  <h2 className="font-size-10 mb-0">
                    what customers and partners say.
                  </h2>
                </div>
              </div>
              <div
                className="col-lg-5 col-md-3"
                data-aos="fade-left"
                data-aos-delay="500"
              >
                <div className="testimonial-one-button text-center text-md-right mt-3 mt-md-18">
                  <button
                    type="button"
                    className="slick-prev"
                    onClick={() => {
                      elSlider.current.slickPrev();
                    }}
                  >
                    <i className="icon icon-minimal-left"></i>
                  </button>
                  <button
                    type="button"
                    className="slick-next"
                    onClick={() => {
                      elSlider.current.slickNext();
                    }}
                  >
                    <i className="icon icon-minimal-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Section Title --> */}
          {/* <!-- category slider --> */}
          <div className="row">
            <div className="col-12">
              <div className="testimonial-one">
                <Slider
                  ref={elSlider}
                  {...slickSettings}
                  css={`
                    .slick-dots {
                      display: flex !important;
                    }
                  `}
                >
                  {items.map((item, index) => (
                    <div className="single-category focus-reset" key={index}>
                      <TestimonialCard1 options={item} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          {/* <!-- End category slider --> */}
        </div>
      </div>
    </>
  );
};

export default Testimonial;
